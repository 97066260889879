
import React from 'react';
import '../css/Loader.css';

function Load(props) {
  return (
    <div id='preloader'>
      <div id='loader'>
      </div>
      {/* <img src={require('../assets/images/toplogo.jpeg')} className="center-test"></img> */}
    </div>
    
  );
}
export default Load;
